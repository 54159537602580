import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Articles from "./components/Articles";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import { account, databases } from "./appwrite/services";
import { ToastContainer, toast } from "react-toastify";
import { Query } from "appwrite";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Button } from "./components/Button";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={<MainPage />} />
				<Route path="/passwordrecover" element={<RecoverPassword />} />
				<Route path="/properties/:type/:id" element={<PropertyDetails />} />
				<Route path="/contactus" element={<ContactUS />} />
				<Route path="/terms" element={<Terms />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/about" element={<About />} />
			</Routes>
		</BrowserRouter>
	);
};

const MainPage = () => {
	const [show, setShow] = useState(false);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);
	if (show) {
		document.body.classList.add("block-overflow");
	}
	if (!show) {
		document.body.classList.remove("block-overflow");
	}
	return (
		<Fragment>
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />
			<Hero />

			<Articles />
			<Footer />
		</Fragment>
	);
};

const PropertyDetails = () => {
	let { id, type } = useParams();
	const [show, setShow] = useState(false);
	const [property, setProperty] = useState([]);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);

	useEffect(() => {
		if (show) {
			document.body.classList.add("block-overflow");
		} else {
			document.body.classList.remove("block-overflow");
		}
	}, [show]);

	useEffect(() => {
		if (type !== "Rent" && type !== "Buy") {
			window.location.href = "/";
		}
	}, [type]);

	useEffect(() => {
		async function fetchProperty() {
			const response = await databases.listDocuments("Properties", type, [
				Query.equal("$id", id),
			]);
			console.log(response.documents[0]);
			setProperty(response.documents[0]);
		}
		fetchProperty();
	}, [type, id]);

	return (
		<div className="min-h-screen bg-gray-50">
			<ToastContainer className="z-50" />
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />

			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
					<div className="rounded-xl overflow-hidden shadow-lg bg-white">
						<Carousel showThumbs={false} className="w-full">
							{property.Images?.map((image, index) => (
								<div key={`image-${index}`} className="aspect-w-16 aspect-h-9">
									<img
										src={image}
										className="w-full h-full object-cover"
										alt={`View ${index + 1} of property`}
									/>
								</div>
							))}
						</Carousel>
					</div>

					<div className="space-y-6 lg:pl-8">
						<h1 className="text-3xl font-bold text-gray-900">
							{property.proprtyName}
						</h1>

						<div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
							<div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
								<FontAwesomeIcon
									icon={["fas", "money-bill"]}
									className="text-rose-500 text-xl"
								/>
								<span className="text-gray-700 font-medium">
									{property.Price}
								</span>
							</div>

							<div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
								<FontAwesomeIcon
									icon={["fas", "bed"]}
									className="text-rose-500 text-xl"
								/>
								<span className="text-gray-700 font-medium">
									{property.beds} Beds
								</span>
							</div>

							<div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
								<FontAwesomeIcon
									icon={["fas", "bath"]}
									className="text-rose-500 text-xl"
								/>
								<span className="text-gray-700 font-medium">
									{property.Baths} Baths
								</span>
							</div>

							<div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
								<FontAwesomeIcon
									icon={["fas", "couch"]}
									className="text-rose-500 text-xl"
								/>
								<span className="text-gray-700 font-medium">
									{property.LivingRooms} Living
								</span>
							</div>

							<div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
								<FontAwesomeIcon
									icon={["fas", "maximize"]}
									className="text-rose-500 text-xl"
								/>
								<span className="text-gray-700 font-medium">
									{property.Size}
								</span>
							</div>

							<div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
								<FontAwesomeIcon
									icon={["fas", "landmark"]}
									className="text-rose-500 text-xl"
								/>
								<span className="text-gray-700 font-medium">
									{property.Type?.join(", ")}
								</span>
							</div>
						</div>

						<div className="bg-white rounded-lg p-6 shadow">
							<h2 className="text-xl font-semibold mb-4">Description</h2>
							<p className="text-gray-600">{property.description}</p>
						</div>

						<div className="bg-white rounded-lg p-6 shadow">
							<h2 className="text-xl font-semibold mb-4">Facilities</h2>
							<p className="text-gray-600">{property.Facilities?.join(", ")}</p>
						</div>

						<div className="flex flex-col sm:flex-row gap-4">
							<button
								type="button"
								className="w-full sm:w-auto px-6 py-3 bg-[#f8545f] text-white rounded-lg hover:bg-rose-600 transition-colors"
								onClick={() => {
									console.log("hi");
								}}
							>
								Download
							</button>

							{property.matterport && (
								<button
									type="button"
									className="w-full sm:w-auto px-6 py-3 bg-[#f8545f] text-white rounded-lg hover:bg-rose-600 transition-colors"
									onClick={() => {
										window.location.href = property.matterport;
									}}
								>
									Virtual Tour
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="mt-12">
					<div className="bg-white rounded-xl shadow-lg overflow-hidden">
						<iframe
							title="Property Location"
							src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&z=15&output=embed`}
							className="w-full h-[400px]"
						/>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

const RecoverPassword = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const userId = urlParams.get("userId");
	const secret = urlParams.get("secret");

	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [show, setShow] = useState(false);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);
	if (show) {
		document.body.classList.add("block-overflow");
	}
	if (!show) {
		document.body.classList.remove("block-overflow");
	}

	if (!secret || !userId) {
		window.location.href = "/";
		return;
	}

	const HandleChangePassword = async () => {
		if (password !== passwordConfirm) {
			toast.error("Password and Confirm Password not match");
			return;
		}
		try {
			const response = await account.updateRecovery(
				userId,
				secret,
				password,
				passwordConfirm,
			);
			console.log(response);
			toast.success("Password updated successfully", {
				onClose: () => {
					window.location.href = "/";
				},
				autoClose: 5000,
			});
		} catch (error) {
			toast.error("Error updating password", {
				autoClose: 5000,
			});
		}
	};

	return (
		<div>
			<ToastContainer className="toast" />
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />

			<div className="container">
				<div className="card">
					<h2>Reset your password</h2>

					<div className="inputBox">
						<label>New Password</label>
						<input
							type="password"
							name=""
							required=""
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<label>Confirm Password</label>
						<input
							type="password"
							name=""
							required=""
							value={passwordConfirm}
							onChange={(e) => setPasswordConfirm(e.target.value)}
						/>
					</div>
					<input
						className="submit"
						type="submit"
						name=""
						value="Submit"
						onClick={HandleChangePassword}
					/>
				</div>
			</div>

			<Footer />
		</div>
	);
};

const ContactUS = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [show, setShow] = useState(false);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);

	useEffect(() => {
		if (show) {
			document.body.classList.add("block-overflow");
		} else {
			document.body.classList.remove("block-overflow");
		}
	}, [show]);

	const HandleSubmitForm = async () => {
		const mailtoLink = `mailto:info@baitkw.com?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(
			`Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`,
		)}`;
		window.location.href = mailtoLink;
	};

	return (
		<div className="min-h-screen bg-gray-50">
			<ToastContainer className="z-50" />
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />

			<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
				<div className="bg-white rounded-2xl shadow-lg p-8">
					<h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
						اتصل بنا
					</h2>

					<div className="space-y-6">
						<div>
							<label className="block text-sm font-medium text-gray-700 mb-1">
								اسمك (مطلوب)
							</label>
							<input
								type="text"
								required
								value={name}
								onChange={(e) => setName(e.target.value)}
								className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200"
							/>
						</div>

						<div>
							<label className="block text-sm font-medium text-gray-700 mb-1">
								بريدك الإلكتروني (مطلوب)
							</label>
							<input
								type="email"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200"
							/>
						</div>

						<div>
							<label className="block text-sm font-medium text-gray-700 mb-1">
								الموضوع
							</label>
							<input
								type="text"
								required
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200"
							/>
						</div>

						<div>
							<label className="block text-sm font-medium text-gray-700 mb-1">
								رسالتك (مطلوبة)
							</label>
							<textarea
								required
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								rows="4"
								className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200 resize-none"
							/>
						</div>

						<button
							onClick={HandleSubmitForm}
							className="w-full bg-[#f8545f] text-white font-medium py-3 px-6 rounded-lg hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 transition duration-200"
						>
							إرسال رسالة
						</button>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

const Terms = () => {
	const [show, setShow] = useState(false);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);
	if (show) {
		document.body.classList.add("block-overflow");
	}
	if (!show) {
		document.body.classList.remove("block-overflow");
	}

	return (
		<div>
			<ToastContainer className="toast" />
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />

			<div className="Terms">
				<h2>Terms and Conditions</h2>
				<p>
					1. Acceptance of the Terms and Conditions of Use Agreement
					<br />
					<br />
					These terms and conditions and the disclaimer of liability in this
					usage agreement apply to your use of the{" "}
					<strong id="baitcolor"> baitkw.com </strong> website and application.
					Your use of this platform, whether by browsing only, registering as a
					member, or adding ads, is considered as an unconditional approval of
					these terms and conditions, exemption from liability, and related
					information included in this agreement. The Bait Real Estate Platform
					Company owns the <strong id="baitcolor"> baitkw.com </strong> website
					and application, which is an electronic platform that provides
					advertising spaces for its users, and Bouaqar’s role is limited to
					providing these advertising spaces. And the accuracy of the
					information contained in the advertisements, its content, reliability,
					or the suitability of the information displayed on this platform for
					any purpose, and therefore relying on the information contained in
					this site is purely your personal responsibility, and hereby you
					exempt the company from any responsibility for claims or claims that
					may arise in this regard .
					<br />
					<br />
					2. Obligations related to the user account and registration on the
					platform
					<br />
					<br />
					When you register as a member of the platform, you warrant and
					acknowledge that the information you give us in the registration form
					(Registration Data) for Bayt is true, accurate, current, complete and
					official information about you as an independent individual or your
					business entity (company or organization) and that you are the person
					legally authorized to provide this information and take responsibility
					You are fully legal to use it and that this information and evidence
					must contain your trade license or other files or evidence that show
					the members of the entity and your entitlement or the entitlement of
					any person in the entity to act with your account and that you will
					agree to give <strong id="baitcolor"> baitkw.com </strong> any
					information or evidence upon request. In the event that you refuse to
					give the aforementioned, <strong id="baitcolor"> baitkw.com </strong>{" "}
					may restrict, suspend or close your account on the site and / or your
					membership as well. No person or business entity can register as a
					member more than once. When you register on the platform, you agree to
					maintain and update the data required upon registration to keep it
					accurate, current and complete. In the event that information is
					provided that is not true, inaccurate, not current or incomplete, or
					if we suspect that this information is not true, inaccurate, not
					current or incomplete or does not apply to the User Agreement and is
					not subject to the laws and conditions of{" "}
					<strong id="baitcolor"> baitkw.com </strong>{" "}
					<strong id="baitcolor"> baitkw.com </strong> has the right to stop,
					end, close your account on the site and / or your membership on the
					site without you having the right to claim anything. When you become a
					member, you agree that <strong id="baitcolor"> baitkw.com </strong>{" "}
					has the right (by) its option and At any time, he may make any inquiry
					he deems important (whether directly or through an intermediary) and
					ask you to give him information or other proofs without any
					restrictions to guarantee your identity and / or ownership of your
					financial covenants. And without restriction to the user. In the event
					that the user is a commercial entity, the This information and
					evidence must contain your trade license or other files or evidence
					that show the members of the entity or the right of any person in the
					entity to act with your account and that you will agree to give{" "}
					<strong id="baitcolor"> baitkw.com </strong> any information or
					evidence upon request.In the event that you refuse to give the
					aforementioned, baitkw .com may restrict, suspend or close your
					account and/or your membership as well. without having the right to
					claim anything. In addition, we reserve the right to suspend any
					inactive or inactive account for a long period of time. By becoming a
					member, you agree to be responsible for maintaining the privacy and
					restricting use of your account and password and you acknowledge full
					responsibility for activities that occur under your account and
					password. And that you will inform{" "}
					<strong id="baitcolor"> baitkw.com </strong> of any illegal use of
					your password or private account or any security breach.{" "}
					<strong id="baitcolor"> baitkw.com </strong> will not be liable for
					any direct or indirect loss due to the disclosure of your username
					and/or password. You cannot use another person's account without
					showing the acceptance statement to the account holder. Also, your
					membership means that you agree to compensate{" "}
					<strong id="baitcolor"> baitkw.com </strong> for any illegal use of
					your own account by you or by any other person who can access the site
					and services by using your username and password, whether you know or
					not about this use and you acknowledge You assume your full and sole
					legal responsibility for the accuracy of any materials, information,
					data, and/or images that you upload and/or post on the Site and you
					acknowledge that such materials, information, data, and/or images are
					not infringing or infringing. The rights of third parties. You also
					acknowledge that we are not responsible for these materials, and you
					bear full responsibility towards us and towards any third parties as a
					result of your failure to comply with this clause, and that we agree
					to publish any materials, information, data, and / or images that you
					upload / and / or publish on The website does not imply in any way
					that we assume any responsibility arising from it. You must not use
					this website to copy, post or transmit any material that is illegal or
					illegal under any applicable laws and regulations, any material that
					is copied, posted or transmitted through this website. The website
					must not be defamatory, obscene, indecent, offensive to public peace
					and morals, misleading, inaccurate, deceptive, or infringing on the
					rights of persons, nor should this application be used in an illegal,
					illegal, fraudulent or harmful manner in any way. Shapes. We have the
					right not to publish and / or delete any material, comment or image
					that does not comply with the terms of this agreement or is not
					commensurate with the site policy, and we also have the right to
					cancel the registration, if any). loss, claim or expense, including
					attorneys' fees, in connection with your breach of this Agreement or
					your use of the Site.
					<br />
					<br />
					3. Block your access to the site and/or suspend your membership
					<br />
					<br />
					Under this agreement, <strong id="baitcolor"> baitkw.com </strong> has
					the right immediately and without any legal liability to limit your
					activity, limit or cancel your membership and / or restrict your
					access to the site at any time without notice for any reason
					whatsoever including but not limited to violation of these terms of
					use. If <strong id="baitcolor"> baitkw.com </strong> cannot confirm or
					verify any information provided by you. If{" "}
					<strong id="baitcolor"> baitkw.com </strong> believes in its sole
					discretion that any of your actions may give rise to legal liability
					against you, other users or{" "}
					<strong id="baitcolor"> baitkw.com </strong>. This and without you
					having the right to claim anything,{" "}
					<strong id="baitcolor"> baitkw.com </strong> has the right at any time
					as it deems appropriate to stop suspending previously suspended users.
					A suspended or suspended user shall not, at any time, attempt to
					register or use <strong id="baitcolor"> baitkw.com </strong> in any
					way until <strong id="baitcolor"> baitkw.com </strong> suspends their
					account. Notwithstanding the foregoing, if you breach these Terms of
					Use, bait.com shall have the right to recover any amounts owed to you
					or compensate for any losses or damages caused to{" "}
					<strong id="baitcolor"> baitkw.com </strong> and shall have the right
					to take similar steps that include judicial and / or penal measures As{" "}
					<strong id="baitcolor"> baitkw.com </strong> deems appropriate and
					when needed.
					<br />
					<br />
					4. Electronic communication
					<br />
					<br />
					By completing your membership, you agree that we can contact you by
					e-mail, by sending notices on the Site, or by SMS, and that you
					consent to all agreements, notices, statements, or other
					communications we may provide to you electronically that satisfy legal
					requirements. <strong id="baitcolor"> baitkw.com </strong> will ask
					for your consent during the registration process to send promotional
					messages letting you know about any new change, feature or any
					promotional activities added to the site. If at any time you would
					like to stop receiving promotional messages, you can choose to do so
					by clicking on the link at the bottom of any promotional message.
					<br />
					<br />
					5. Amendments to the User Agreement
					<br />
					<br />
					By completing your membership, you agree that{" "}
					<strong id="baitcolor"> baitkw.com </strong> can amend the user
					agreement to increase your obligations or reduce your rights
					(substantial amendment according to the rules of the user agreement as
					you agree to <strong id="baitcolor"> baitkw.com </strong>) according
					to his choice and at any time) and without any responsibility to make
					non-essential amendments that are clarified on the site Himself.
					<br />
					<br />
					6. Misuse of <strong id="baitcolor"> baitkw.com </strong>
					<br />
					<br />
					Please report any problems of any kind or any violations of these
					Terms of Use to <strong id="baitcolor"> baitkw.com </strong>, if you
					believe that your intellectual property rights have been violated
					please report to <strong id="baitcolor"> baitkw.com </strong>. Without
					prejudice to any of the other rights or measures of{" "}
					<strong id="baitcolor"> baitkw.com </strong>,{" "}
					<strong id="baitcolor"> baitkw.com </strong> has the right to limit,
					suspend or block any user's access to the site or/and his membership
					and is also entitled to delete any content posted by the user. In
					addition, <strong id="baitcolor"> baitkw.com </strong> may take any
					technical or / and legal action against any person who causes problems
					or legal violations of any kind that infringes the intellectual
					property rights of any third party or performs any act contrary to
					these terms of use or our policy.
					<br />
					<br />
					7. There is no guarantee
					<br />
					<br />
					<strong id="baitcolor"> baitkw.com </strong> provides the site and its
					services on an “as is and as available” basis, without any
					representation or endorsement, and without any warranties or
					guarantees of any kind, whether express or implied, including, but not
					limited to, the warranties of title, merchantability, and fitness for
					a particular purpose. Compatibility, Security, Accuracy, and
					Non-Infringement <strong id="baitcolor"> baitkw.com </strong> makes no
					representations or warranties as to the accuracy, reliability, or
					completeness of any content, information, software, text, images,
					links, or communications provided by or through the Site, or that
					operations on the Site will be error-free and unbiased. Sequence
					interruption and <strong id="baitcolor"> baitkw.com </strong> makes no
					guarantees that defects will be corrected or that the site and its
					services are free of viruses or anything else that may cause harm or
					damage. This site may also be unavailable from time to time for
					repair, maintenance or business development purposes.You agree that{" "}
					<strong id="baitcolor"> baitkw.com </strong> has no obligation to
					provide support for this site.You expressly agree that you use this
					site at your own risk.
					<br />
					<br />
					8. Your information
					<br />
					<br />
					By using the <strong id="baitcolor"> baitkw.com </strong> website, you
					give us the right to use any information or data, whether personal or
					otherwise, that you give us or other users and / or registered on the
					site when registering, when advertising, or through any e-mail
					messages or through any feature in The site in order to improve and
					manage the site in accordance with the user agreement and privacy
					policy.
					<br />
					<br />
					9. Proprietary rights
					<br />
					<br />
					The content of the site is complete and not exclusive, whether texts,
					designs, logos, button icons, images, audio clips, digital content,
					data collection, and software are the property rights and copyrights
					of <strong id="baitcolor"> baitkw.com </strong> or its users, as they
					are protected by the rights and laws of property, trademark, or PATENT
					OR ANY INTELLECTUAL PROPERTY OF{" "}
					<strong id="baitcolor"> baitkw.com </strong> The entire content of
					this website is the exclusive property and copyright of{" "}
					<strong id="baitcolor"> baitkw.com </strong> and is protected by
					copyright, trademark, patent or other intellectual property rights.
					<br />
					<br />
					10. Trademarks
					<br />
					<br />
					<strong id="baitcolor"> baitkw.com </strong> and any related logos,
					words or slogans included on the Site are either a registered or
					unregistered trademark of the Site and are protected by international
					trademark and other intellectual property rights and laws. The
					trademarks of <strong id="baitcolor"> baitkw.com </strong> may not be
					used in any product or service that is not affiliated with{" "}
					<strong id="baitcolor"> baitkw.com </strong> nor in any way that
					discredits <strong id="baitcolor"> baitkw.com </strong>.
					<br />
					<br />
					11. Privacy
					<br />
					<br />
					<strong id="baitcolor"> baitkw.com </strong> may take reasonable
					measures (physical, organizational, technological) to protect against
					unauthorized access to your personally identifiable information and to
					safely store your personally identifiable information. Note that the
					Internet is not a secure medium and the privacy of your personal
					information cannot be guaranteed.{" "}
					<strong id="baitcolor"> baitkw.com </strong> cannot control the
					practices of third parties (such as a website linking to this site or
					a third party impersonating you or someone else). You agree that{" "}
					<strong id="baitcolor"> baitkw.com </strong> can use your personal
					information that you give us in order to provide services and send you
					promotional content and that the site's privacy policy governs the
					collection, processing, use and transfer of any personal information
					about you.
					<br />
					<br />
					12. Limitation of responsibilities
					<br />
					<br />
					To the extent permitted by law,{" "}
					<strong id="baitcolor"> baitkw.com </strong>, its officers, employees,
					affiliates and suppliers shall not be liable for any losses or damages
					of any kind whether direct, indirect, incidental, special,
					consequential or exemplary including but not limited to such losses or
					damages. resulting from loss of profits, goodwill, opportunity, data,
					or other intangible losses arising out of or related to your use of
					the Website, its Services or the User Agreement. If you are
					dissatisfied with the site, its content or products, your only remedy
					is to stop using it. Further, you agree that any unauthorized use of
					the Website or its Services as a result of your recklessness and
					inaction may cause irreparable problems for{" "}
					<strong id="baitcolor"> baitkw.com </strong>, and{" "}
					<strong id="baitcolor"> baitkw.com </strong> will deal with any such
					unauthorized uses under the terms and conditions of the User Terms.
					This is amazing.
					<br />
					<br />
					13. Compensation
					<br />
					<br />
					You agree to indemnify and hold{" "}
					<strong id="baitcolor"> baitkw.com </strong>, its affiliates, officers
					and employees harmless from any disputes, complaints, actions,
					actions, losses, liabilities or expenses (including reasonable legal
					costs and expenses) of any kind arising from your breach of these
					Terms. User or violation of any - laws or rights of a third party.
					<br />
					<br />
					14. Relationships and Notices
					<br />
					<br />
					All the items mentioned in the Terms of Use Agreement do not
					constitute or constitute a partnership or mediation between you and{" "}
					<strong id="baitcolor"> baitkw.com </strong> and you have no right to
					oblige <strong id="baitcolor"> baitkw.com </strong> to anything in any
					way.
					<br />
					<br />
					15. Transfer of rights and obligations
					<br />
					<br />
					You (the User) hereby grant the right to{" "}
					<strong id="baitcolor"> baitkw.com </strong> and irrevocably represent
					that <strong id="baitcolor"> baitkw.com </strong> can at any time
					transfer all or part of its rights, obligations, benefits or
					responsibilities (whether expressed or assumed) under the User
					Agreement to its affiliate partners without reference to requesting
					additional approval from you.{" "}
					<strong id="baitcolor"> baitkw.com </strong> agrees to use all
					reasonable endeavors to notify you of any transfer by posting on the
					Site. You may not at any time without the prior written consent of{" "}
					<strong id="baitcolor"> baitkw.com </strong> transfer all or part of
					your rights, obligations, benefits or responsibilities (whether
					expressed or assumed) under this User Agreement without the prior
					written consent of <strong id="baitcolor"> baitkw.com </strong>.
					<br />
					<br />
					16. General Terms
					<br />
					<br />
					If any clause of the User Agreement is found null, void, or for any
					reason unenforceable, such as if the clause is separable, this must
					not affect the implementation and validity of the rest of the clauses
					in this document. This User Terms Agreement (as amended from time to
					time in accordance with the terms of that document) defines in advance
					all the terms of understanding and agreement between you and{" "}
					<strong id="baitcolor"> baitkw.com </strong>, taking into account the
					issues related to that. No person who is not a party to this User
					Terms Agreement has the right to impose any condition.
					<br />
					<br />
					governing law
					<br />
					<br />
					This User Agreement shall be governed by and subject to the applicable
					law of the State of Kuwait.
					<br />
					<br />
				</p>

				<p id="ara">
					1. قبول اتفاقية الاستخدام والشروط والأحكام
					<br />
					<br />
					تنطبق هذه الشروط والأحكام والإعفاء من المسؤولية في اتفاقية الاستخدام
					هذه على استخدامكم لموقع وتطبيق بيت{" "}
					<strong id="baitcolor"> baitkw.com </strong>. ويعتبر استخدامكم لهذه
					المنصة سواء بالتصفح فقط أو التسجيل كعضو أو إضافة الإعلانات بمثابة
					موافقة غير مُعلقة على قيد أو شرط على تلك الشروط والأحكام والإعفاء من
					المسؤولية والمعلومات ذات العلاقة المدرجة في هذه الإتفاقية. تمتلك شركة
					منصة بيت العقارية موقع وتطبيق بيت{" "}
					<strong id="baitcolor"> baitkw.com </strong> وهي منصة إلكترونية توفر
					مساحات إعلانية لمستخدميها ويقتصر دور بوعقار على توفير هذه المساحات
					الإعلانية ولا تتحمل أي مسؤولية ولا توفر أى ضمانات أو تعهدات اتجاه
					المستخدمين من أي نوع كانت - صريحة أو ضمنية - فيما يتعلق باكتمال ودقة
					المعلومات الواردة بالإعلانات ومضمونها أو موثوقيتها أو ملاءمة المعلومات
					المعروضة في هذه المنصة لأي غرض كان ، ومن ثم فإن الإستناد إلى المعلومات
					الواردة بهذا الموقع هو على مسؤوليتكم الشخصية البحته، وبموجب هذا فأنكم
					تعفون الشركة من أي مسؤولية عن دعاوى أو مطالبات قد تنشأ في هذا الصدد.
					<br />
					<br />
					2. الالتزامات الخاصة بحساب المستخدم والتسجيل في المنصة
					<br />
					<br />
					عند التسجيل كعضو فى المنصة ، فإنك تتعهد وتقر بأن المعلومات التي تمنحها
					لنا فى استمارة التسجيل بيانات التسجيل ) الخاصة ب بيت هي معلومات صحيحة
					ودقيقة و حالية وكاملة ورسمية عنك كفرد مستقل أو عن كيانك التجاري شركة
					أو مؤسسة وأنك الشخص المخول قانونا بتقديم هذه المعلومات وتحمل المسؤولية
					القانونية الكاملة لإستخدامها وأن هذه المعلومات والاثباتات يجب أن تحتوى
					على رخصتك التجارية أو غيرها من الملفات أو الاثباتات التي تظهر أعضاء
					الكيان وأحقيتك أو أحقية أى شخص في الكيان بالتصرف بحسابك وأنك ستوافق
					على اعطاء <strong id="baitcolor"> baitkw.com </strong> أي معلومات أو
					اثباتات عند الطلب. في حالة رفضك لاعطاء ما سبق ذكره فإن{" "}
					<strong id="baitcolor"> baitkw.com </strong> قد يقيد أو يوقف أو يغلق
					حسابك الخاص بالموقع و / أو عضويتك أيضاً. ولا يمكن لأى شخص أو كيان تجارى
					التسجيل كعضو أكثر من مرة. عند التسجيل في المنصة فإنك توافق على أن
					تحافظ و تحدث البيانات المطلوبة عند التسجيل لتبقى دقيقة وحالية و كاملة.
					فى حالة تقديم معلومات غير حقيقية أو غير دقيقة أو غير حالية أو غير
					كاملة، أو فى حالة الاشتباه من طرفنا بأن هذه المعلومات غير حقيقية أو
					غير دقيقة أو غير حالية أو غير كاملة أو لا تنطبق مع إتفاقية المستخدم
					وغير خاضعة لقوانين و شروط <strong id="baitcolor"> baitkw.com </strong>{" "}
					، فإن <strong id="baitcolor"> baitkw.com </strong> له الحق فى إيقاف،
					وضع حد، اغلاق الحساب الخاص بك فى الموقع و / أو عضويتك بالموقع دون أن
					يكون لك الحق في المطالبة بأي شيء عندما تصبح عضو، فإنك توافق على أنه
					يحق ل<strong id="baitcolor"> baitkw.com </strong> (بموجب) اختياره و فى
					أى وقت أن يقوم بأى استعلام يراه مهماً (سواء مباشرة أو عن طريق وسيط و أن
					يطلب منك اعطائه معلومات أو اثباتات أخرى بدون أى قيود لضمان هويتك و/أو
					ملكيتك لعهودك المالية. وبدون تقييد للمستخدم أما في حالة كان المستخدم
					كيان ،تجارى ، فإن هذه المعلومات والاثباتات يجب أن تحتوى على رخصتك
					التجارية أو غيرها من الملفات أو الاثباتات التي تظهر أعضاء الكيان أو
					أحقية أى شخص في الكيان بالتصرف بحسابك وأنك ستوافق على اعطاء
					<strong id="baitcolor"> baitkw.com </strong> أي معلومات أو اثباتات عند
					الطلب. في حالة رفضك لاعطاء ما سبق ذكره، فإن{" "}
					<strong id="baitcolor"> baitkw.com </strong> قد يقيد أو يوقف أو يغلق
					حسابك الخاص بالموقع و/ أو عضويتك أيضاً. دون أن يكون لك الحق في المطالبة
					بأي شيء. بالإضافة إلى أننا نحتفظ بأحقيتنا بوقف أى حساب غير مفعّل أو غير
					نشط لفترة طويلة من الزمن. عندما تصبح عضو، فإنك توافق على مسؤوليتك في
					الحفاظ على خصوصية وتقييد استخدام حسابك الخاص و الرقم السرى الخاص بك
					كما تقر بمسؤليتك الكاملة تجاه الأنشطة التي تحدث من خلال حسابك الخاص و
					رقمك السرى. و أنك ستقوم بإبلاغ{" "}
					<strong id="baitcolor"> baitkw.com </strong> عن أي استخدام غير مشروع
					لرقمك السرى أو حسابك الخاص أو أى خرق أمنى. لن يكون{" "}
					<strong id="baitcolor"> baitkw.com </strong> مسئولاً عن أي خسارة مباشرة
					أو غير مباشرة بسبب الإفصاح عن اسم المستخدم الخاص بك و / أو رقمك السرى.
					لا يمكنك استخدام الحساب الخاص بشخص آخر بدون اظهار تصريح القبول لحامل
					الحساب. كما أن عضويتك تعنى موافقتك على تعويض{" "}
					<strong id="baitcolor"> baitkw.com </strong> عن أي استخدام غير مشروع
					لحسابك الخاص عن طريقك أو عن طريق أى شخص آخر يستطيع الوصول للموقع و
					الخدمات من خلال استخدام اسم المستخدم الخاص بك و رقمك السرى سواء كنت
					تعلم أو لا تعلم بهذا الإستخدام وأنت تقر بمسؤوليتك القانونية الكاملة
					والمنفردة عن دقة أي مواد و/ أو معلومات و/ أو بيانات و/ أو صور تقوم
					بتحميلها و/ أو نشرها على الموقع كما أنك تقر بأن تلك المواد و/أو
					المعلومات و/ أو البيانات و/ أو الصور لا تخل أو تنتهك حقوق أطراف ثالثة
					وكذلك انت تقر بعدم مسؤوليتنا عن هذه المواد وتتحمل كامل المسؤولية
					اتجاهنا واتجاه أي أطراف ثالثة نتيجة عدم التزامك بهذا البند وان
					موافقتنا على نشر أي مواد و/أو معلومات و/ أو بيانات و / أو صور تقوم
					بتحميلها/ و / أو نشرها على الموقع لا تعني بأي شكل من الأشكال تحملنا أي
					مسؤولية ناشئة عنها. يجب عدم استخدام هذا الموقع لنسخ أو نشر أو إرسال أى
					مواد غير قانونية أو غير مشروعة بموجب أية قوانين ولوائح معمول بها، أية
					مواد يتم نسخها أو نشرها أو إرسالها من خلال هذا. الموقع يجب الا تكون
					تشهيرية أو خادشة للحياء أو غير لائقة أو مؤثرة على السلم العام والآداب
					العامة أو مضللة أو غير دقيقة أو خادعة أو تمثل انتهاك لحقوق الأشخاص،
					كما يجب عدم استخدام هذا التطبيق بطريقة غير قانونية أو غير مشروعة أو
					احتيالية أو ضارة بأي شكل من الأشكال. يحق لنا عدم نشر و / أو حذف أي
					مادة أو تعليق أو صورة لا تتوافق مع شروط هذه الاتفاقية أو لا يتناسب مع
					سياسة الموقع كما يحق لنا الغاء التسجيل ان وجد) كما أنك توافق على
					تعويضنا والمدافعة عنا وإخلاء مسؤوليتنا وشركائنا ومحامينا وموظفينا من
					أي مسؤولية أو خسارة أو إدعاء أو نفقات بما في ذلك أتعاب المحاماة ، فيما
					يتعلق بإخلالك لنصوص هذه الاتفاقية أو استعمالك للموقع
					<br />
					<br />
					3. حجب دخولك للموقع و/أو إيقاف عضويتك
					<br />
					<br />
					بموجب هذه الإتفاقية يحق لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> فورا وبدون أى مسؤولية
					قانونية الحد من نشاطك والحد أو إلغاء عضويتك و/ أو تقييد دخولك للموقع
					فى أي وقت بدون اشعار لأي سبب من الأسباب متضمنة على سبيل المثال وليس
					الحصر انتهاك شروط الإستخدام هذه. إذا لم يتمكن{" "}
					<strong id="baitcolor"> baitkw.com </strong> من التأكد أو التحقق من أي
					معلومات مقدمة من قبلك. إذا اعتقد{" "}
					<strong id="baitcolor"> baitkw.com </strong> بتقديره منفردا أن أي من
					تصرفاتك قد تؤدى إلى مساءلة قانونية ضدك أو ضد مستخدمين آخرين أو ضد{" "}
					<strong id="baitcolor"> baitkw.com </strong>. هذا ومن دون أن يكون لك
					الحق في المطالبة بأي شيء يحق لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> في أي وقت وفق مايراه
					مناسبا وقف تعليق المستخدمين المعلقة حساباتهم سابقا. لا يحق للمستخدم
					المستبعد أو الموقوفة عضويته فى أى وقت أن يقوم بـ أو أن يحاول التسجيل
					أو استخدام <strong id="baitcolor"> baitkw.com </strong> بأي شكل من
					الأشكال حتى يقوم <strong id="baitcolor"> baitkw.com </strong> بوقف
					تعليق حسابه. وبغض النظر عن ما سبق فإنه اذا قمت باختراق شروط الإستخدام
					هذه, فإنه يحق لـ bait.com إستعادة أي مبالغ مستحقة تمتلكها أنت أو
					التعويض عن أي خسائر أو أضرار تسببت بها ل{" "}
					<strong id="baitcolor"> baitkw.com </strong> ويحق له اتخاذ خطوات
					مماثلة تتضمن اجراءات قضائية و / أو جزائية وفق مايراه{" "}
					<strong id="baitcolor"> baitkw.com </strong> مناسبا وعند الحاجة لذلك
					<br />
					<br />
					4. التواصل الإلكترونى
					<br />
					<br />
					بإكمال عضويتك فإنك توافق على أننا يمكننا الاتصال بك عبر البريد
					الإلكترونى أو عن طريق ارسال اشعارات على الموقع أو عن طريق الرسائل
					النصية القصيرة وأنك توافق على كل الاتفاقيات والإشعارات أو التصريحات أو
					أى اتصالات أخرى يمكننا تقديمها إليك إلكترونيا بحيث تستوفى المتطلبات
					القانونية. سيطلب <strong id="baitcolor"> baitkw.com </strong> الحصول
					على موافقتك خلال عملية التسجيل لارسال رسائل ترويجية تسمح لك بمعرفة أى
					تغير جديد أو خاصية أو أى أنشطة ترويجية تضاف إلى الموقع. إذا رغبت في أي
					وقت ايقاف الرسائل الترويجية التى تصلك، يمكنك اختيار ذلك عن طريق الضغط
					على الرابط فى أسفل أى رسالة ترويجية
					<br />
					<br />
					5.تعديلات لإتفاقية المستخدم
					<br />
					<br />
					باكمال عضويتك فإنك توافق أن
					<strong id="baitcolor"> baitkw.com </strong> يمكنه تعديل إتفاقية
					المستخدم بما يزيد إلتزاماتك أو يقلل من حقوقك (تعديل جوهري طبقا لقواعد
					إتفاقية المستخدم كما توافق على
					<strong id="baitcolor"> baitkw.com </strong> ) بموجب اختياره وفى أى
					وقت) و بدون أى مسؤولية أن يقوم بتعديلات غير جوهرية يتم توضحيها على
					الموقع نفسه
					<br />
					<br />
					6. إساءة إستخدام <strong id="baitcolor"> baitkw.com </strong>
					<br />
					<br />
					الرجاء الإبلاغ عن أي مشاكل من أي نوع أو أي إنتهاكات لشروط الإستخدام
					هذه عن طريق <strong id="baitcolor"> baitkw.com </strong> ، إذا كنت
					تعتقد بأن حقوق ملكيتك الفكرية قد تم انتهاكها الرجاء إبلاغ{" "}
					<strong id="baitcolor"> baitkw.com </strong> . دون المساس بأي من
					الحقوق الأخرى أو التدابير التابعة لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> يحق ل{" "}
					<strong id="baitcolor"> baitkw.com </strong> الحد من أو تعليق أو حجب
					دخول أي مستخدم للموقع أو / و عضويته ويحق له أيضا حذف أي محتوى مضاف من
					قبل المستخدم. بالإضافة إلى أن{" "}
					<strong id="baitcolor"> baitkw.com </strong> قد يتخذ أي اجراء فنى أو /
					و قانوني تجاه أي شخص يتسبب بمشاكل أو انتهاكات قانونية من أي نوع بحيث
					يخترق حقوق الملكية الفكرية لأي طرف ثالث أو يقوم بأي تصرف مخالف لشروط
					الإستخدام هذه أو سياستنا
					<br />
					<br />
					7. لا يوجد ضمان
					<br />
					<br />
					يقدم <strong id="baitcolor"> baitkw.com </strong> الموقع و خدماته وفق
					قاعدة " كما هي و حسب توافرها بدون أى تمثيل أو تظهير و بدون أي ضمانات
					أو كفالة من أى نوع سواء مصرح بها أو ضمنية، فيما يشتمل على سبيل المثال
					وليس الحصر على ضمانات العنوان و الرواج والملاءمة لأغراض معينة و
					التوافق والأمان والدقة وعدم التعدي.{" "}
					<strong id="baitcolor"> baitkw.com </strong> لايوفر توضيحات أو ضمانات
					فيما يتعلق بالدقة والموثوقية أو كمال أى محتوى أو معلومات أوبرمجيات أو
					نصوص أو صور أو روابط أو الاتصالات التي يوفرها الموقع أو من خلال
					استخدامه أو أن العمليات على الموقع ستكون خالية من الأخطاء ومن انقطاع
					التسلسل ولا يقدم <strong id="baitcolor"> baitkw.com </strong> ضمانات
					بأن يتم إصلاح العيوب أو أن الموقع و خدماته خاليين من الفيروسات أو أى
					شيء أخر من الممكن أن يسبب الضرر أو التلف. قد يكون هذا الموقع وفقا
					لطبيعة الإتصالات عبر الإنترنت عرضة لإتلاف بياناته أو إيقافها أو عدم
					توافرها أو تأخرها. يمكن لهذا الموقع أيضاً ان يكون غير متاح من وقت لأخر
					لأغراض التصليح أو الصيانة أو تطوير العمل. و توافق أنت على أن{" "}
					<strong id="baitcolor"> baitkw.com </strong> غير ملتزم بتقديم الدعم
					لهذا الموقع . وتوافق موافقة واضحة على أنك تستخدم هذا الموقع على
					مسئوليتك الشخصية.
					<br />
					<br />
					8. معلوماتك
					<br />
					<br />
					بإستخدامك موقع <strong id="baitcolor"> baitkw.com </strong> فانك تعطى
					الحق لنا باستخدام أى معلومات أو بيانات سواء شخصية أو غيرها من التي
					تمنحنا إياها أو لمستخدمين آخرين و / أو مسجلة على الموقع عند التسجيل أو
					عند الإعلان أو من خلال أى رسائل على البريد الإلكتروني أو عن طريق أى
					خاصية فى الموقع وذلك من أجل تحسين وإدارة الموقع وذلك طبقا لإتفاقية
					المستخدم و سياسة الخصوصية.
					<br />
					<br />
					9. حقوق الملكية
					<br />
					<br />
					إن محتوى الموقع كاملا و ليس حصريا سواء من نصوص أو تصاميم أو شعارات
					وأيقونات الأزرار و الصور و مقاطع الصوت و المحتويات الرقمية ومجموعة
					البيانات و البرمجيات هي حقوق ملكية وطبع ونشر لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> أو مستخدميه حيث أنها
					محمية بموجب حقوق وقوانين الملكية أو العلامة التجارية أو براءة الإختراع
					أو أى خاصية فكرية لـ <strong id="baitcolor"> baitkw.com </strong> إن
					مجموع محتوى هذا الموقع هو ذو حقوق ملكية حصرية وحقوق طبع ونشر لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> ومحمي بموجب حقوق وقوانين
					النشر أو العلامة التجارية أو براءات الاختراع أو أي خاصية فكرية أخرى.
					<br />
					<br />
					10. العلامات التجارية
					<br />
					<br />
					إن <strong id="baitcolor"> baitkw.com </strong> وأي شعارات متعلقة به
					أو كلمات أو شعارات مدرجة في الموقع هى إما علامة تجارية مسجلة أو غير
					مسجلة للموقع ومحمية بموجب علامة تجارية دولية و حقوق وقوانين أخرى
					للملكية الفكرية. لا يجوز استخدام العلامات التجارية لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> في أي منتج أو خدمة لا
					تنتمى إلى <strong id="baitcolor"> baitkw.com </strong> ولا بأي شكل يسئ
					إلى <strong id="baitcolor"> baitkw.com </strong>.
					<br />
					<br />
					11. الخصوصية
					<br />
					<br />
					قد يتخذ <strong id="baitcolor"> baitkw.com </strong> بعض الإجراءات
					المعقولة مادية، تنظيمية، تكنولوجية) للحماية من الدخول غير المسموح
					لمعلومات هويتك الشخصية ولحفظها بأمان أيضاً. علما بأن الانترنت ليس وسط
					آمن ولايمكن ضمان خصوصية معلوماتك الشخصية. ولا يمكن لـ{" "}
					<strong id="baitcolor"> baitkw.com </strong> التحكم في ممارسات الطرف
					الثالث (مثل موقع يعرض رابط هذا الموقع أو طرف ثالث ينتحل شخصيتك أو شخص
					آخر). إنك توافق على أن <strong id="baitcolor"> baitkw.com </strong>{" "}
					يمكنه استخدام معلوماتك الشخصية التي تمنحنا إياها من أجل تقديم الخدمات
					و ارسال محتويات دعائية لك و أن سياسة الخصوصية للموقع تحكم جمع ومعالجة
					واستخدام و نقل أى معلومات شخصية خاصة بك.
					<br />
					<br />
					12. حدود المسئوليات
					<br />
					<br />
					في حدود مايسمح به القانون فإن{" "}
					<strong id="baitcolor"> baitkw.com </strong> و مسؤوليه و موظفيه
					والشركات التابعة له والموردين لا يتحملون أي مسؤولية عن أي خسائر أو
					أضرار من أي نوع سواء بطريقة مباشرة أو غير مباشرة أو عرضية أو خاص أو
					تبعي أو نموذجي يتضمن ولا يقتصر على الخسائر أو الأضرار الناجمة عن خسارة
					الأرباح أو سمعة الشركة أو الفرص أو البيانات أو خسائر أخرى غير ملموسة
					ناجمة عن أو مرتبطة باستخدامك للموقع، أو خدماته أو لإتفاقية المستخدم.
					إذا كنت غير راضى عن الموقع أو محتواه أو منتجاته، فالحل الوحيد هو أن
					تتوقف عن استخدامها. علاوة على ذلك، فأنت توافق على أن أي استخدام غير
					مصرح به للموقع أو خدماته نتيجة لتهأونك فى رد الفعل ولإمتناعك قد يتسبب
					في مشاكل يتعذر اصلاحها في <strong id="baitcolor"> baitkw.com </strong>{" "}
					، وسوف يتعامل <strong id="baitcolor"> baitkw.com </strong> مع أي
					استخدامات غير مصرح بها مماثلة بموجب بنود وأحكام شروط المستخدم هذه.
					<br />
					<br />
					13. التعويضات
					<br />
					<br />
					توافق أنت على التعويض و حماية{" "}
					<strong id="baitcolor"> baitkw.com </strong> و الشركات التابعة له
					ومسؤوليه وموظفيه من الإضرار الناجمة عن أي نزاعات أو شكاوى أو تصرفات
					أوجراءات أو خسائر أو مساءلات أو نفقات فيما يتضمن أيضاً التكاليف و
					النفقات القانونية المنطقية)، من أي نوع والناجمة عن انتهاكك لإتفاقية
					شروط المستخدم أو انتهاك أي - من القوانين أو الحقوق الخاصة بالطرف
					الثالث.
					<br />
					<br />
					14. العلاقات والاشعارات
					<br />
					<br />
					كل البنود المذكورة في إتفاقية شروط المستخدم لا تشكل أو تعد شراكة أو
					واسطة بينك وبين<strong id="baitcolor"> baitkw.com </strong> و ليس لك
					الحق بإلزام <strong id="baitcolor"> baitkw.com </strong> بأي شيء وبأي
					شكل من الأشكال.
					<br />
					15. نقل الحقوق و الإلتزمات
					<br />
					<br />
					تمنح أنت (المستخدم) بموجب هذه الإتفاقية الحق ل
					<strong id="baitcolor"> baitkw.com </strong> و تقر إقرارا لا رجعة فيه
					أنه يمكن لـ <strong id="baitcolor"> baitkw.com </strong> في أى وقت نقل
					كل أو جزء من حقوقه أو إلتزماته أو منافعه أو مسئولياته ( سواء المعبر
					عنها أو المفترضة) بموجب إتفاقية المستخدم إلى شركائه التابعين له بدون
					الرجوع الى طلب موافقة إضافية منك. يوافق{" "}
					<strong id="baitcolor"> baitkw.com </strong> على استخدام كل المساعي
					المتاحة لإخطارك بأي نقل عن طريق النشر على الموقع . لا يمكنك فى أى وقت
					وبدون موافقة خطية سابقة من{" "}
					<strong id="baitcolor"> baitkw.com </strong> كنقل كل أو جزء من حقوقك
					أو التزماتك أو منافعك أو مسئولياتك ( سواء المعبر عنها أو المفترضة)
					بموجب هذة الإتفاقية للمستخدم بدون موافقة خطية مسبقة من{" "}
					<strong id="baitcolor"> baitkw.com </strong>.
					<br />
					<br />
					16. بنود عامة
					<br />
					<br />
					إذا وجد أى بند من إتفاقية المستخدم باطلا أو لاغيا أو لأى سبب من
					الأسباب غير قابل للتنفيذ كأن يكون البند قابل للفصل يجب أن لا يؤثر ذلك
					على تنفيذ وصلاحية باقى البنود في هذه الوثيقة. وتحدد إتفاقية شروط
					المستخدم هذه مسبقا (كما عدلت من حين لأخر طبقاً لبنود تلك الوثيقة كل
					بنود التفاهم والإتفاق بينك وبين{" "}
					<strong id="baitcolor"> baitkw.com </strong> مع الأخذ بعين الإعتبار
					المواضيع المتعلقة بذلك. لا يحق لأي شخص ليس طرفا في إتفاقية شروط
					المستخدم هذه أن يفرض أى شرط ما.
					<br />
					<br />
					القانون الحاكم
					<br />
					<br />
					تتبع إتفاقية المستخدم هذه وتخضع للقانون المعمول به في دولة الكويت.
				</p>
				<br />
				<br />
			</div>

			<Footer />
		</div>
	);
};

const Privacy = () => {
	const [show, setShow] = useState(false);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);
	if (show) {
		document.body.classList.add("block-overflow");
	}
	if (!show) {
		document.body.classList.remove("block-overflow");
	}

	return (
		<div>
			<ToastContainer className="toast" />
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />

			<div className="Terms">
				<h2>Privacy Policy</h2>
				<p>
					Bait - بيت built the Bait app as a Free app. This SERVICE is provided
					by Bait - بيت at no cost and is intended for use as is.
					<br />
					This page is used to inform visitors regarding our policies with the
					collection, use, and disclosure of Personal Information if anyone
					decided to use our Service.
					<br />
					If you choose to use our Service, then you agree to the collection and
					use of information in relation to this policy. The Personal
					Information that we collect is used for providing and improving the
					Service. We will not use or share your information with anyone except
					as described in this Privacy Policy.
					<br />
					The terms used in this Privacy Policy have the same meanings as in our
					Terms and Conditions, which are accessible at Bait unless otherwise
					defined in this Privacy Policy.
					<br />
					<strong>Information Collection and Use</strong>
					<br />
					For a better experience, while using our Service, we may require you
					to provide us with certain personally identifiable information,
					including but not limited to Name, Phone Number, Email. The
					information that we request will be retained by us and used as
					described in this privacy policy.
					<br />
					The app does use third-party services that may collect information
					used to identify you.
					<br />
					Link to the privacy policy of third-party service providers used by
					the app
					<br />
					<ul>
						<li>
							<a
								href="https://www.google.com/policies/privacy/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Google Play Services
							</a>
						</li>
						<li>
							<a
								href="https://expo.io/privacy"
								target="_blank"
								rel="noopener noreferrer"
							>
								Expo
							</a>
						</li>
					</ul>
					<br />
					We want to inform you that whenever you use our Service, in a case of
					an error in the app we collect data and information (through
					third-party products) on your phone called Log Data. This Log Data may
					include information such as your device Internet Protocol (“IP”)
					address, device name, operating system version, the configuration of
					the app when utilizing our Service, the time and date of your use of
					the Service, and other statistics.
					<br />
					<strong>Cookies</strong>
					<br />
					Cookies are files with a small amount of data that are commonly used
					as anonymous unique identifiers. These are sent to your browser from
					the websites that you visit and are stored on your device's internal
					memory.
					<br />
					This Service does not use these “cookies” explicitly. However, the app
					may use third-party code and libraries that use “cookies” to collect
					information and improve their services. You have the option to either
					accept or refuse these cookies and know when a cookie is being sent to
					your device. If you choose to refuse our cookies, you may not be able
					to use some portions of this Service.
					<br />
					<strong>Service Providers</strong>
					<br />
					We may employ third-party companies and individuals due to the
					following reasons:
					<br />
					<ul>
						<li>To facilitate our Service;</li>{" "}
						<li>To provide the Service on our behalf;</li>{" "}
						<li>To perform Service-related services; or</li>{" "}
						<li>To assist us in analyzing how our Service is used.</li>
					</ul>
					<br />
					We want to inform users of this Service that these third parties have
					access to their Personal Information. The reason is to perform the
					tasks assigned to them on our behalf. However, they are obligated not
					to disclose or use the information for any other purpose.
					<br />
					<strong>Security</strong>
					<br />
					We value your trust in providing us your Personal Information, thus we
					are striving to use commercially acceptable means of protecting it.
					But remember that no method of transmission over the internet, or
					method of electronic storage is 100% secure and reliable, and we
					cannot guarantee its absolute security.
					<br />
					<strong>Links to Other Sites</strong>
					<br />
					This Service may contain links to other sites. If you click on a
					third-party link, you will be directed to that site. Note that these
					external sites are not operated by us. Therefore, we strongly advise
					you to review the Privacy Policy of these websites. We have no control
					over and assume no responsibility for the content, privacy policies,
					or practices of any third-party sites or services.
					<br />
					<strong>Children’s Privacy</strong>
					<br />
					These Services do not address anyone under the age of 13. We do not
					knowingly collect personally identifiable information from children
					under 13 years of age. In the case we discover that a child under 13
					has provided us with personal information, we immediately delete this
					from our servers. If you are a parent or guardian and you are aware
					that your child has provided us with personal information, please
					contact us so that we will be able to do the necessary actions.
					<br />
					<strong>Changes to This Privacy Policy</strong>
					<br />
					We may update our Privacy Policy from time to time. Thus, you are
					advised to review this page periodically for any changes. We will
					notify you of any changes by posting the new Privacy Policy on this
					page.
					<br />
					This policy is effective as of 2023-10-31
					<br />
					<strong>Contact Us</strong>
					<br />
					If you have any questions or suggestions about our Privacy Policy, do
					not hesitate to contact us at info@baitkw.com.
				</p>
			</div>

			<Footer />
		</div>
	);
};

const About = () => {
	const [show, setShow] = useState(false);
	const openModal = () => setShow(true);
	const closeModal = () => setShow(false);

	useEffect(() => {
		if (show) {
			document.body.classList.add("block-overflow");
		} else {
			document.body.classList.remove("block-overflow");
		}
	}, [show]);

	return (
		<div className="min-h-screen bg-gray-50">
			<ToastContainer className="z-50" />
			<Modal closeModal={closeModal} show={show} />
			<Navbar openModal={openModal} closeModal={closeModal} show={show} />

			<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
				<div className="bg-white rounded-2xl shadow-lg p-8">
					<h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
						About Us
					</h2>

					<div className="space-y-8">
						<div className="prose max-w-none">
							<p className="text-lg text-gray-700 leading-relaxed mb-8">
								Bait is a real estate platform. Our goal is to satisfy the
								customer by using the best methods and technologies. Our goal is
								also to market your property in the latest ways to speed up the
								process of marketing your property and selling or renting it as
								soon as possible.
							</p>

							<p
								className="text-lg text-gray-700 leading-relaxed text-right"
								dir="rtl"
							>
								بيت هي منصة عقارية هدفنا ارضاء العميل باستخدام افضل الطرق
								والتقنيات وهدفنا ايضاً تسويق عقارك باحدث الطرق لتسريع عملية تسويق
								عقارك وبيعه او تأجيره باسرع وقت ممكن
							</p>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default App;
library.add(fab, fas, far);
