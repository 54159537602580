import React, { useEffect, useState, useCallback } from "react";
import "./css/Articles.css";
import Article from "./Article";
import { databases } from "../appwrite/services";
import { Query } from "appwrite";

const Articles = () => {
	const [data, setData] = useState([]);
	const [search, setSearch] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const limit = 12;

	const fetchProperties = useCallback(
		async (currentOffset, searchQuery = "") => {
			try {
				setIsLoading(true);
				const queries = [Query.limit(limit), Query.offset(currentOffset)];

				if (searchQuery) {
					queries.push(Query.search("proprtyName", searchQuery));
					queries.push(Query.search("code", searchQuery));
				}

				const [rentProperties, saleProperties] = await Promise.all([
					databases.listDocuments("Properties", "Rent", queries),
					databases.listDocuments("Properties", "Buy", queries),
				]);

				const newProperties = [
					...rentProperties.documents,
					...saleProperties.documents,
				];

				// Update total count on first load
				if (currentOffset === 0) {
					const totalItems = rentProperties.total + saleProperties.total;
					setTotalCount(totalItems);
				}

				// Check if we've reached the end by comparing total loaded items against total count
				const currentTotal = currentOffset + newProperties.length;
				setHasMore(currentTotal < totalCount);

				if (currentOffset === 0) {
					// If it's a new search, replace the data
					setData(newProperties);
					setFilteredData(newProperties);
				} else {
					// If it's "load more", append the data
					setData((prevData) => [...prevData, ...newProperties]);
					setFilteredData((prevData) => [...prevData, ...newProperties]);
				}
			} catch (error) {
				console.error("Error fetching properties:", error);
			} finally {
				setIsLoading(false);
			}
		},
		[],
	);

	useEffect(() => {
		fetchProperties(0);
	}, [fetchProperties]);

	const handleSearch = (event) => {
		const searchQuery = event.target.value.toLowerCase();
		setSearch(searchQuery);
		setOffset(0); // Reset offset when searching
		setHasMore(true); // Reset hasMore

		// If search is empty, fetch initial data
		if (!searchQuery) {
			fetchProperties(0);
			return;
		}

		// Filter existing data while fetching new results
		const filtered = data.filter(
			(property) =>
				property.proprtyName.toLowerCase().includes(searchQuery) ||
				`${property.code}`.toLowerCase().includes(searchQuery),
		);
		setFilteredData(filtered);

		// Fetch new results from server
		fetchProperties(0, searchQuery);
	};

	const loadMore = () => {
		const newOffset = offset + limit;
		setOffset(newOffset);
		fetchProperties(newOffset, search);
	};

	return (
		<section className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8" id="properties">
			<div className="max-w-7xl mx-auto">
				<div className="mb-8">
					<div className="max-w-md mx-auto">
						<div className="relative">
							<div className="absolute left-4 top-1/2 transform -translate-y-1/2">
								<svg
									className="w-5 h-5 text-gray-400"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									aria-label="Search icon"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
									/>
								</svg>
							</div>
							<input
								type="text"
								value={search}
								onChange={handleSearch}
								placeholder="ابحث بالأسم او كود العقار"
								className="w-full px-6 py-4 bg-white border border-gray-200 rounded-full shadow-sm focus:ring-2 focus:ring-rose-500 focus:border-transparent outline-none transition duration-200 text-gray-900 placeholder-gray-500"
								dir="rtl"
							/>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
					{filteredData.map((property) => (
						<Article
							key={property.$id}
							coll={property.$collectionId}
							cover={property.proprtyImg}
							id={property.$id}
							title={property.proprtyName}
							excerpt={property.description}
							price={
								property.$collectionId === "Rent"
									? `${property.Price} KWD/Month`
									: `${property.Price} KWD`
							}
						/>
					))}
				</div>

				<div className="mt-12 text-center">
					<button
						type="button"
						onClick={loadMore}
						disabled={isLoading}
						className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#f8545f] hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 transition duration-200 disabled:opacity-50"
					>
						{isLoading ? "جاري التحميل..." : "تحميل المزيد من العقارات"}
					</button>
				</div>
			</div>
		</section>
	);
};

export default Articles;
