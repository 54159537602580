import React from "react";
import "./css/Article.css";

const Article = (props) => {
	return (
		<a
			href={`./properties/${props.coll}/${props.id}`}
			className="block overflow-hidden rounded-lg bg-white shadow-lg transition-transform hover:scale-[1.02] hover:shadow-xl"
		>
			<div className="relative pb-[56.25%]">
				<img
					src={props.cover}
					alt={props.title}
					className="absolute inset-0 h-full w-full object-cover"
				/>
			</div>

			<div className="p-6">
				<h4 className="mb-2 text-xl font-semibold text-gray-900 line-clamp-1">
					{props.title}
				</h4>

				<p className="mb-4 text-sm text-gray-600 line-clamp-2">
					{props.excerpt}
				</p>

				<div className="flex flex-row-reverse items-center justify-between">
					<span className="text-lg font-bold text-rose-500">
						{props.price.replace("Month", "شهر")}
					</span>
					<span className="rounded-full bg-rose-100 px-3 py-1 text-sm font-medium text-rose-600">
						{props.coll}
					</span>
				</div>
			</div>
		</a>
	);
};

export default Article;
