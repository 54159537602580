import React from "react";
import "./css/Hero.css";
import Button from "./Button";

const Hero = () => (
	<section className="Hero" id="home">
		<div className="hero__container">
			<div className="hero__container__bg"></div>
			<div>
				<h1 className="hero__container__title">منصة بيت العقارية</h1>

				<p className="hero__container__desc" style={{ color: "#000" }}>
					منصة بيت العقارية هي وجهتك العقارية الفريدة في عالم العقارات بجميع
					أنواعها، نسعى لتقديم تجربة استثنائية من خلال عرض متنوع بالعقارات التي
					تلبي احتياجاتك{" "}
				</p>
				<div className="hero__container_stores">
					<Button
						className="hero_button_stores"
						link="./#RequestInvite"
						cont="App store"
					/>
					<Button
						className="hero_button_stores"
						link="./#RequestInvite"
						cont="Google play"
					/>
				</div>
			</div>
		</div>
	</section>
);

export default Hero;
